import strings from '../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import RadioAdGroups from './pages/RadioAdGroups'
import RadioAdGroup from './pages/RadioAdGroup/RadioAdGroup'

export const RadioAdGroupsRoute: Route = {
  path: '/radio-ad-groups',
  name: strings.navigation.radioAdGroups,
  exact: true,
  component: RadioAdGroups,
}

export const RadioAdGroupsCreateRoute: Route = {
  path: '/radio-ad-groups/create',
  name: strings.navigation.radioAdGroups,
  exact: true,
  component: RadioAdGroup,
}

export const RadioAdGroupsEditRoute: ParametrizedRoute = {
  path: '/radio-ad-groups/edit/:playlistId',
  name: strings.navigation.radioAdGroups,
  exact: true,
  component: RadioAdGroup,
  getUrl: ({ playlistId }) => `/radio-ad-groups/edit/${playlistId}`,
}
