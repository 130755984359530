/* eslint-disable @typescript-eslint/indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable quotes */
import React, { Dispatch, useEffect, useState, useRef } from 'react'

import useStores from '../../common/hook/useStore'

import Box from '@mui/material/Box'
import Text from '../../locale/strings'
import Select from '../../ui/Select/Select'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton'
import { OptionType } from '../../common/api/common/common'
import { toProductTypeOptions } from '../../common/constants'

import { AppPrice } from '../../common/api/app/app'

import styles from './prices.module.scss'

const availableStreamOptions = {
  count: 'count',
  minutes: 'minutes',
}

const productTypes = [
  { id: 'STYNG', name: 'STYNG', availableStreamOption: '' },
  { id: 'STYNGLIST', name: 'STYNGLIST', availableStreamOption: '' },
  { id: 'SUBSCRIPTION', name: 'SUBSCRIPTION', availableStreamOption: '' },
  { id: 'SUBSCRIPTION_EMOTE', name: 'SUBSCRIPTION_EMOTE', availableStreamOption: '' },
  { id: 'SUBSCRIPTION_BUNDLE_EMOTE_FREE', name: 'SUBSCRIPTION_BUNDLE_EMOTE_FREE', availableStreamOption: '' },
  { id: 'SUBSCRIPTION_BUNDLE_EMOTE_PAID', name: 'SUBSCRIPTION_BUNDLE_EMOTE_PAID', availableStreamOption: '' },
  {
    id: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_SMALL',
    name: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_SMALL',
    availableStreamOption: availableStreamOptions.count,
    price: 0.3,
  },
  {
    id: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_MEDIUM',
    name: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_MEDIUM',
    availableStreamOption: availableStreamOptions.count,
    price: 0.46,
  },
  {
    id: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_LARGE',
    name: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_LARGE',
    availableStreamOption: availableStreamOptions.count,
    price: 3.99,
  },
  {
    id: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_FREE',
    name: 'SUBSCRIPTION_RADIO_STREAM_BUNDLE_FREE',
    availableStreamOption: availableStreamOptions.count,
    price: 0,
  },
  {
    id: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_SMALL',
    name: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_SMALL',
    availableStreamOption: availableStreamOptions.minutes,
  },
  {
    id: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_MEDIUM',
    name: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_MEDIUM',
    availableStreamOption: availableStreamOptions.minutes,
  },
  {
    id: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_LARGE',
    name: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_LARGE',
    availableStreamOption: availableStreamOptions.minutes,
  },
  {
    id: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_FREE',
    name: 'SUBSCRIPTION_RADIO_TIME_BUNDLE_FREE',
    availableStreamOption: availableStreamOptions.minutes,
  },
]

interface ProcesProps {
  setNewPrices: Dispatch<React.SetStateAction<AppPrice[]>>
  appId?: string
  prices?: AppPrice[]
}

const Prices = ({ appId, prices, setNewPrices }: ProcesProps) => {
  const { notificationsStore } = useStores()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [productTypesOptions, setProductTypesOptions] = useState<OptionType[]>([])
  const [currentPrices, setCurrentPrices] = useState<AppPrice[]>([])

  useEffect(() => {
    setProductTypesOptions(productTypes.map(toProductTypeOptions))
    setCurrentPrices(prices ?? [])
  }, [])

  useEffect(() => {
    setNewPrices([...currentPrices])
  }, [currentPrices])

  const onAddClick = () => {
    setCurrentPrices([
      ...currentPrices,
      {
        productType: '',
        price: 4.99,
        availableStreamCount: '',
        availableStreamMinutes: '',
        appId: appId,
        currency: {
          appId: appId ?? '',
          id: 3,
          imageId: '7709f3d2-b704-4b5e-b3c3-0288441970b3',
          name: 'STAR',
        },
      },
    ])
  }

  const onDeleteClick = (key: number) => {
    setCurrentPrices((currentPrices) => {
      return currentPrices.filter((_, i) => i !== key)
    })
  }

  const handleProductTypeChange = (selectedValue: string, priceIndex: number) => {
    const selectedOptionValue = selectedValue
    let isAlreadySelected = false

    currentPrices.forEach((el) => {
      if (selectedOptionValue === el.productType) {
        notificationsStore.infoNotification('Product type already added to the list')

        isAlreadySelected = true
      }
    })

    if (!isAlreadySelected) {
      setCurrentPrices((currentPrices) =>
        currentPrices.map((price, index) =>
          index === priceIndex ? { ...price, productType: selectedOptionValue } : price,
        ),
      )

      handleInputUpdate('', priceIndex, 'availableStreamCount')
      handleInputUpdate('', priceIndex, 'availableStreamMinutes')
    }
  }

  const handleInputUpdate = (selectedValue: string | number | null, priceIndex: number, inputType: string) => {
    setCurrentPrices((currentPrices: AppPrice[]) =>
      currentPrices.map((item: AppPrice, i: number) =>
        i === priceIndex ? { ...item, [inputType]: selectedValue } : item,
      ),
    )
  }

  const disableStreamInput = (selectedProductTypeName: string, streamOption: string) => {
    if (selectedProductTypeName === '') {
      return true
    }

    const getSelectedProductType = productTypes.filter(
      (productTypeOption) => productTypeOption.id === selectedProductTypeName,
    )[0]

    if (getSelectedProductType?.availableStreamOption !== streamOption) {
      return true
    }

    return false
  }

  const renderPricesRows = () => {
    return currentPrices.map((item, index: number) => (
      <div className={styles.priceRow} key={index}>
        <Select
          fullWidth
          data-test="product-type-field"
          className={styles.productTypeSelect}
          label={Text.page.appEvents.create.fields.productType}
          name="productType"
          value={item.productType}
          options={productTypesOptions}
          onChange={(e) => handleProductTypeChange(e, index)}
        />
        <TextField
          data-test="price-field"
          type="number"
          className={styles.textField}
          label={Text.page.appEvents.create.fields.price}
          name={Text.page.appEvents.create.fields.price}
          value={item.price}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'price')}
        />
        <TextField
          data-test="available-stream-count-field"
          type="number"
          inputRef={inputRef}
          disabled={disableStreamInput(item.productType, availableStreamOptions.count)}
          className={styles.textField}
          label={Text.page.appEvents.create.fields.availableStreamCount}
          name={Text.page.appEvents.create.fields.availableStreamCount}
          value={item.availableStreamCount}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'availableStreamCount')}
        />
        <TextField
          data-test="available-stream-minutes-field"
          type="number"
          inputRef={inputRef}
          disabled={disableStreamInput(item.productType, availableStreamOptions.minutes)}
          className={styles.textField}
          label={Text.page.appEvents.create.fields.availableStreamMinutes}
          name={Text.page.appEvents.create.fields.availableStreamMinutes}
          value={item.availableStreamMinutes}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'availableStreamMinutes')}
        />
        <DeleteIcon color={'action'} className={styles.deleteIcon} onClick={() => onDeleteClick(index)} />
      </div>
    ))
  }

  return (
    <Box>
      <div className={styles.body}>
        <div className={styles.rowWrapper}>{renderPricesRows()}</div>
        <div className={styles.addNewwWrapper}>
          <div className={styles.addButtonRow}>
            <LoadingButton
              data-test="add-button"
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<AddIcon />}
              onClick={() => onAddClick()}
            >
              <span>ADD</span>
            </LoadingButton>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default Prices
