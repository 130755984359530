import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import RadioAdGroupsTable from './RadioAdGroupsTable/RadioAdGroupsTable'

const RadioAdGroups = () => {
  return <RadioAdGroupsTable />
}

export default thread(RadioAdGroups, [observer])
