import strings from '../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import RadioAds from './pages/RadioAds'
import RadioAd from './pages/RadioAd/RadioAd'

export const RadioAdsRoute: Route = {
  path: '/radio-ads',
  name: strings.navigation.radioAds,
  exact: true,
  component: RadioAds,
}

export const RadioAdCreateRoute: Route = {
  path: '/radio-ads/create',
  name: strings.navigation.radioAds,
  exact: true,
  component: RadioAd,
}

export const RadioAdEditRoute: ParametrizedRoute = {
  path: '/radio-ads/edit/:id',
  name: strings.navigation.radioAds,
  exact: true,
  component: RadioAd,
  getUrl: ({ id }) => `/radio-ads/edit/${id}`,
}
