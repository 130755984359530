import PaginationStore from '../common/PaginationStore'
import { observable, action } from 'mobx'
import { useQuery } from 'react-query'
import * as R from 'ramda'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import { RadioAdsResponse } from '../common/api/radioAds/radioAds'

class AdsStore extends PaginationStore {
  @observable
  id: string = ''

  @observable
  name: string = ''

  @observable
  ageRestriction: string = ''

  @observable
  availability: string = ''

  @observable
  adId: string = ''

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handleAgeRegistrationSearch = (filter: string) => {
    this.ageRestriction = filter?.trim()
    this.changePage()
  }

  @action
  handleTerritorySearch = (filter: []) => {
    this.availability = filter.toString()
    this.changePage()
  }
}

export const useAdsListQuery = (filterStore: AdsStore) => {
  const { pageSize, page, id, name, ageRestriction, availability } = filterStore

  return useQuery<RadioAdsResponse>(
    ['adsList', pageSize, page, id, name, ageRestriction, availability],
    () => {
      return API.radioAds.adsList({
        size: pageSize,
        page: page,
        id,
        name,
        ageRestriction,
        availability,
      })
    },
    {
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useAdQuery = (adParam: Nullable<string> = null) => {
  const { radioAdsStore } = useStores()
  const { adId: adStore } = radioAdsStore
  const id = adParam ?? adStore

  return useQuery<any>(
    ['ad', id],
    () => {
      return API.radioAds.getAd({
        id: id,
      })
    },
    {
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default AdsStore
