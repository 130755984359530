/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useMutation } from 'react-query'

import useStores from 'common/hook/useStore'

import { RadioAdGroupsCreateRoute, RadioAdGroupsEditRoute } from '../..'

import { useAdGroupsListQuery } from '../../AdGroupsStore'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import Button from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'

import Text from 'locale/strings'

import API from 'common/api'
import { truncateText } from 'common/utils'
import { ApiStatuses, FilterFields } from 'common/constants'
import { RadioAdGroup } from 'common/api/radioAdGroups/radioAdGroups'

import styles from './RadioAdGroupsTable.module.scss'

const INITIAL_PAGE = 1

const RadioAdGroupsTable = () => {
  const { adGroupsStore, navigationStore, notificationsStore } = useStores()
  const { data, status, refetch } = useAdGroupsListQuery(adGroupsStore)
  const [numberOfPages, setNumberOfPages] = useState<number>(INITIAL_PAGE)
  const adGroups = data?.items ?? []

  const onDeleteMutation = useMutation<any, Error, any>(
    (body: any) => {
      return API.playlist.deleteAdGroup({ playlistId: body.playlistId, adGroupId: body.adGroupId })
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('Ad Group successfully deleted')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  useEffect(() => {
    return () => {
      adGroupsStore.changePage(INITIAL_PAGE)
    }
  }, [])

  useEffect(() => {
    if (data?.pageCount) {
      setNumberOfPages(data.pageCount)
    }
  }, [data])

  const handleCreateAdGroup = () => {
    navigationStore.goToPage(RadioAdGroupsCreateRoute.path)
  }

  const handleEditClick = (adGroupId: string) => {
    const adGroupObjForEdit: RadioAdGroup | undefined = adGroups.find((adGroup) => adGroup.id === adGroupId)
    const playlistIdForEdit: string = adGroupObjForEdit?.playlistId ?? ''

    if (playlistIdForEdit !== '') {
      navigationStore.goToPage(RadioAdGroupsEditRoute.getUrl({ playlistId: playlistIdForEdit }))
    }
  }

  const columns = ['Id', 'Bound to Playlist', 'Total # of ads', 'Actions']

  const dataTable = adGroups.map((adGroup: RadioAdGroup) => [
    adGroup.id,
    truncateText(adGroup.playlistName),
    adGroup.ads.length,
  ])

  const handleChangePage = (value: number) => {
    adGroupsStore.changePage(value)
  }

  const handleDeleteClick = (id: string) => {
    const adGroupObject = adGroups.find((adGroup: RadioAdGroup) => adGroup.id === id)

    const body = {
      playlistId: adGroupObject?.playlistId,
      adGroupId: id,
    }

    onDeleteMutation.mutate(body)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreateAdGroup()}>
          {Text.radioAdGroups.create}
        </Button>
      </div>
      <SectionFilters sectionStore={adGroupsStore} filters={[FilterFields.ID, FilterFields.PLAYLIST]} />

      {status === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Table columns={columns} data={dataTable} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
          {numberOfPages > 0 && (
            <div>
              <div className={styles.paginationContainer}>
                <div className={styles.pagination}>
                  <Pagination page={adGroupsStore.page} count={numberOfPages} onChange={handleChangePage} />
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default observer(RadioAdGroupsTable)
