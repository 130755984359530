import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import RadioAdsStore from '../RadioAdsStore'

import RadioAdsTable from './RadioAdsTable/RadioAdsTable'

const RadioAds = () => {
  const radioAdsStore = new RadioAdsStore()

  return <RadioAdsTable radioAdsStore={radioAdsStore} />
}

export default thread(RadioAds, [observer])
